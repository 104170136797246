import React from 'react'
import { Link } from 'gatsby'

import MegaMenu from './MegaMenu'
import headerLogo from '../assets/images/tomi-logo-large-2x.png'

const Header = () => {
  const [mobileNavActive, setmobileNavActive] = React.useState(false)
  const [megaMenuActive, setmegaMenuActive] = React.useState(false)
  const [slide, setslide] = React.useState(0)
  const [ossURL, setossURL] = React.useState('')

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    setossURL(window['OSS_URL'])
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  function handleScroll() {
    setslide(window.scrollY > 110 ? '-110px' : '0')
  }

  function toggleMobileNav(e) {
    e.preventDefault()
    setmobileNavActive(!mobileNavActive)
  }

  return (
    <header
      className={`${megaMenuActive ? 'mega-menu-active' : ''} ${
        mobileNavActive ? 'menu-active' : ''
      }`}
      style={{
        transform: `translate(0, ${slide})`,
        transition: 'transform 250ms linear',
      }}
    >
      <MegaMenu />

      <div className="brand">
        <Link to="/">
          <img src={headerLogo} alt="Tomi Broadband" />
        </Link>
      </div>

      <nav className="nav nav-mobile">
        <ul>
          <li>
            <a href="tel:1300655982" className="phone" title="Call Tomi">
              <em className="fa fa-phone" />
              <span className="no-display-description">Call 1300655982</span>
            </a>
          </li>
          <li>
            <button
              type="button"
              className="menu-toggle"
              onClick={toggleMobileNav}
            >
              <em className="fa fa-bars" />
              <span className="no-display-description">
                Tomi Mobile Navigation Menu
              </span>
            </button>
          </li>
        </ul>
      </nav>

      <nav className="nav nav-main">
        <ul>
          <li>
            <Link
              to="/"
              onMouseEnter={() => {
                setmegaMenuActive(true)
              }}
              onMouseLeave={() => {
                setmegaMenuActive(false)
              }}
              className="trigger-mega-menu"
            >
              nbn™
            </Link>
          </li>
          <li>
            <Link to="/plans-pricing">Plans & Pricing</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <a href="tel:1300655982" className="phone" title="Call Tomi">
              1300 655 982
            </a>
          </li>
          <li>
            <a href={ossURL}>
              <span className="btn_sign-in">Sign In</span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}
export default Header
