import React from 'react'
import { Link } from 'gatsby'

const MegaMenu = () => (
  <div className="mega-menu">
    <div className="container">
      <div className="row">
        <div className="col-sm-6">
          <p className="title">nbn Unlimited Plans</p>
          <hr />
          <ul className="list-unstyled">
            <li>
              <Link to="/sign-up/?pl=UN-L-NBN25/5-24M-3" className="plan">
                <span className="title">TOMI HOME BASIC</span>
                <span className="cost">
                  from
                  <span className="charge">
                    59
                    <sup>.95</sup>
                  </span>
                  <span className="period">/mth</span>
                </span>
              </Link>
            </li>

            <li>
              <Link to="/sign-up/?pl=UN-SF-NBN50/20-24M-4" className="plan">
                <span className="title">TOMI HOME STANDARD</span>
                <span className="cost">
                  from
                  <span className="charge">
                    69
                    <sup>.95</sup>
                  </span>
                  <span className="period">/mth</span>
                </span>
                <small style={{ display: 'block' }}>
                  *Free ZTE H1600 wifi device on a 24 Month Contract Term
                </small>
              </Link>
            </li>
            <li>
              <Link to="/sign-up/?pl=UN-HY-NBN100/20-24M-2" className="plan">
                <span className="title">TOMI HOME FAST</span>
                <span className="cost">
                  from
                  <span className="charge">
                    89
                    <sup>.95</sup>
                  </span>
                  <span className="period">/mth</span>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/sign-up/?pl=UN-HY-NBN250/25-24M-1" className="plan">
                <span className="title">TOMI HOME SUPER FAST</span>
                <span className="cost">
                  from
                  <span className="charge">
                    109
                    <sup>.95</sup>
                  </span>
                  <span className="period">/mth</span>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/sign-up/?pl=UN-HY-NBN1000/50-24M-1" className="plan">
                <span className="title">TOMI HOME ULTRA FAST</span>
                <span className="cost">
                  from
                  <span className="charge">
                    139
                    <sup>.95</sup>
                  </span>
                  <span className="period">/mth</span>
                </span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="col-sm-6">
          <p className="title">About the nbn</p>
          <hr />
          <ul>
            <li>
              <Link to="/about-the-nbn">About the nbn™</Link>
            </li>
            <li>
              <Link to="/connect-to-a-better-future">
                Connect to a better future
              </Link>
            </li>
            <li>
              <Link to="/sign-up">Check my address</Link>
            </li>
            <li>
              <Link to="/broadband-usage-guide">Broadband Usage Guide</Link>
            </li>
            <li>
              <Link to="/hardware">Hardware</Link>
            </li>
            <li>
              <Link to="/media-pr">Media &amp; PR</Link>
            </li>
            <li>
              <Link to="/tomi-eero6">eero 6</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default MegaMenu
