import React from 'react'
import { Link } from 'gatsby'

import footerLogo from '../assets/images/tomi-logo-footer.png'

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-3">
          <Link className="logo" to="/">
            <img src={footerLogo} alt="tomi" />
          </Link>

          <div>
            <small>
              Tomi Broadband Pty Ltd
              <br />
              (ACN 600 369 784) trading as Tomi®
              <br />
              Locked Bag 35001
              <br />
              Collins St West Melbourne 8007
            </small>
          </div>

          <br />

          <span className="contact-number">1300 655 982</span>

          <ul>
            <li className="menu-item menu-hellotomi-com-au">
              <a
                href="mailto:hello@tomi.com.au"
                rel="noopener noreferrer"
                title="Email Tomi"
              >
                hello@tomi.com.au
              </a>
            </li>
            <li className="menu-item menu-media-pr-enquiries">
              <Link to="/media-pr">Media &amp; PR Enquiries</Link>
            </li>
          </ul>
        </div>

        <div className="col-sm-6 col-md-3">
          <div className="footer__wrap footer__wrap_split footer__wrap_split_menu_column">
            <p className="header4">Legal</p>

            <ul id="menu-footer-column-two">
              <li className="menu-item menu-what-you-need-to-know">
                <Link to="/what-you-need-to-know">What you need to know</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Tomi™ Privacy Policy</Link>
              </li>
              <li>
                <Link to="/hardware-warranty-policy">
                  Hardware &amp; Warranty Policy
                </Link>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href="/pdf/Tomi-Financial-Hardship-Policy.pdf"
                  title="Tomi Financial Hardship Policy"
                >
                  Financial Hardship Policy
                </a>
              </li>
              <li>
                <a href="/payment-assistance" title="Tomi Payment Assistance">
                  Payment Assistance
                </a>
              </li>
              <li>
                <Link to="/standard-form-of-agreement">SFOA</Link>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href="/pdf/Tomi-Complaints-Handling-Process.pdf"
                  title="Tomi Complaints Handling Process"
                >
                  Complaints
                </a>
              </li>
              <li>
                <Link to="/speed-information-for-nbn">
                  Speed Information for nbn™
                </Link>
              </li>
              <li>
                <Link to="/cis">Critical Information Summary</Link>
              </li>
              <li>
                <Link to="/online-safety">Online Safety</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-sm-6 col-md-3">
          <div className="footer__wrap footer__wrap_split footer__wrap_split_menu_column footer__wrap_split_menu_column_last">
            <p className="header4">Customer Care</p>

            <ul>
              <li className="menu-item menu-switching-to-tomi">
                <Link to="/switching-to-tomi">Switching to Tomi</Link>
              </li>
              <li>
                <Link to="/broadband-usage-guide">Broadband Usage Guide</Link>
              </li>
              <li>
                <Link to="/hardware">Hardware</Link>
              </li>
              <li>
                <a
                  href="mailto:support@tomi.com.au"
                  rel="noopener noreferrer"
                  title="Email Tomi"
                >
                  support@tomi.com.au
                </a>
              </li>
              <li>
                <a
                  href="https://tomibroadband.zendesk.com"
                  title="Go to Tomi Help Center"
                >
                  Help Center
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-sm-6 col-md-3">
          <div className="social-media text-align-center">
            <a
              className="footer__copy--social_icon"
              href="https://www.facebook.com/Tomi-Broadband-1579720262289083/"
              rel="noopener noreferrer"
              title="Go to Tomi Broadband Facebook page"
            >
              <em className="fa fa-facebook-official" />
              <span className="no-display-description">Tomi Facebook</span>
            </a>
            <a
              className="footer__copy--social_icon"
              href="https://www.linkedin.com/company/tomi-broadband"
              rel="noopener noreferrer"
              title="Go to Tomi Broadband Linkedin page"
            >
              <em className="fa fa-linkedin" />
              <span className="no-display-description">Tomi Linkedin</span>
            </a>
            <a
              className="footer__copy--social_icon"
              href="https://plus.google.com/+TomiAus"
              rel="noopener noreferrer"
              title="Go to Tomi Broadband Google+ page"
            >
              <em className="fa fa-google-plus" />
              <span className="no-display-description">Tomi Google+</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
